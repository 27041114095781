.ml__auto {
  margin-left: auto;
}
.mr_auto {
  margin-right: auto;
}
.mt__auto {
  margin-top: auto;
}

.w__100 {
  width: 100% !important;
}

.mb__30 {
  margin-bottom: 30px !important;
}

.mb__20 {
  margin-bottom: 20px !important;
}

.mb__10 {
  margin-bottom: 10px !important;
}

.mr__20 {
  margin-right: 20px !important;
}

.mr__10 {
  margin-right: 10px !important;
}

.mt__20 {
  margin-top: 20px !important;
}

.mt__10 {
  margin-top: 10px !important;
}

.ml__10 {
  margin-left: 10px !important;
}

.ml__20 {
  margin-left: 20px !important;
}
